import axios, { AxiosRequestConfig } from 'axios'

import { IRecord, IReqData, IRes } from './interface'
import './__mock__'

/** 获取商品列表 */
export const apiGetRecords = async (reqData: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  const params = {
    ...reqData,
    searchType: reqData.keyword ? reqData.searchType : undefined,
  }
  return axios.get('/linkhub_oms/shop_product/list', { params, signal }).then(({ $data }) => {
    return {
      ...$data,
      records: ($data?.records || []).map((item: IRecord) => ({
        ...item,
        $key: `${item.sysShopId}-${item.skuId}`,
      })),
    }
  })
}

/** 更新商品 */
export const apiSyncProduct = async (): Promise<number | null | undefined> => {
  return axios.post('/linkhub_oms/shop_product').then(res => res.$data)
}

/** 配对/更换 */
export const apiMatch = async (
  method: 'post' | 'put',
  reqData: {
    localSkuId: string
    shopSkuId: number
    sysShopId: string
  },
) => {
  const url = '/linkhub_oms/shop_product/mapping'
  await axios({
    url,
    method,
    data: reqData,
  })
}
